<template>
    <v-container v-if="aktivitet">
        <v-row>
            <v-col cols="12" class="content">
                <div class="d-flex">
                    <h1>
                        <span v-html="aktivitet.tittel"></span>
                    </h1>
                    <v-btn v-if="canEdit" class="ml-2" color="primary" icon :to="routeEdit">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>

                <div class="content__meta">
                    <span
                        ><strong>Når:</strong> {{ aktivitet.start | format('dddd D. MMMM YYYY [kl. ] HH:mm') }}
                        <template v-if="aktivitet.slutt"> - {{ aktivitet.slutt | format('HH:mm') }} </template> </span
                    ><br />
                    <template v-if="aktivitet.sted || aktivitet.moterom">
                        <span
                            ><strong>Sted:</strong>
                            <template v-if="aktivitet.sted"> {{ aktivitet.sted }}</template>
                            <template v-if="aktivitet.sted && aktivitet.moterom">, </template>
                            <template v-if="aktivitet.moterom"> {{ aktivitet.moterom.navn }}</template>
                        </span>
                    </template>
                    <br />
                    <template v-if="aktivitet.paameldingsfrist">
                        <span><strong>Påmeldingsfrist:</strong> {{ aktivitet.paameldingsfrist | format('dddd D. MMMM') }}</span>
                        <br />
                    </template>
                    <span v-if="aktivitet.antallPlasser > 0 && aktivitet.antallPlasser > antallDeltakere">
                        <strong>Deltakere:</strong> Maks. {{ aktivitet.antallPlasser }} stk.
                        <template v-if="antallDeltakere > 0"> {{ antallDeltakere }} er påmeldt. </template>
                        <template v-else> Ingen påmeldte. </template>
                    </span>
                    <span v-else-if="aktivitet.antallPlasser > 0 && aktivitet.antallPlasser <= antallDeltakere">
                        <strong>Fullbooket</strong> ({{ aktivitet.antallPlasser }} deltakere).
                    </span>
                </div>
                <br />
            </v-col>

            <v-col
                v-if="beskrivelse || (openpamelding && !pameldt)"
                cols="12"
                :sm="beskrivelse ? 9 : 12"
                :md="beskrivelse ? 8 : 12"
                class="content"
                style="margin-top: 0"
            >
                <div class="content__text" v-html="beskrivelse"></div>
                <template v-if="openpamelding && !pameldt">
                    <v-btn @click="participate()" color="primary"> Meld meg på </v-btn>
                    <!--
                    <v-btn v-if="!aktivitet.signed_up" @click="signup(true)" color="primary"> Meld meg på </v-btn>
                    <v-btn v-else-if="aktivitet.signed_up" @click="signup(false)" color="primary"> Meld meg av </v-btn>
                    <br />
                    <br />
                    -->
                </template>
                <template v-else-if="pameldt">
                    <strong>Du er påmeldt.</strong>
                    <template v-if="openpamelding && pameldt">
                        <br />
                        <br />
                        <v-btn @click="unparticipate()" color="primary">Meld meg av</v-btn>
                    </template>
                </template>
                <template v-else-if="type == 'SORGGRUPPE'">
                    <br />
                    <v-btn @click="registerReport()" color="primary"> Registrer rapport </v-btn>
                </template>
            </v-col>
            <v-col
                cols="12"
                :sm="beskrivelse ? 2 : 12"
                :offset-sd="beskrivelse ? 1 : 0"
                :md="beskrivelse ? 3 : 12"
                :offset-md="beskrivelse ? 1 : 0"
                v-if="type == 'SORGGRUPPE' || (visDeltakere && aktivitet.deltakere)"
            >
                <strong>Deltakere:</strong><br />
                <div v-for="deltakere in aktivitet.deltakere" :key="deltakere.id">
                    <template v-if="!deltakere.fjernet"> {{ deltakere.person.fulltNavn }}</template>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'Activity',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tab: null,
            aktivitet: null,

            sorggruppe: null,
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
        type: function () {
            return this.aktivitet && this.aktivitet.type ? this.aktivitet.type.id : null;
        },
        canEdit: function () {
            if (this.hasRole('ANSATT')) {
                return true;
            }
            if (this.hasRole('FRIVILLIG_SORG')) {
                if (this.sorggruppe && this.sorggruppe.ledere) {
                    for (const leder of this.sorggruppe.ledere) {
                        if (!leder.fjernet) {
                            if (leder.person.id == this.user.id) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        },
        routeEdit: function () {
            if (this.aktivitet) {
                if (this.aktivitet.type.id == 'SORGGRUPPE') {
                    return '/sorg/gruppemote/' + this.aktivitet.id + '/rediger';
                }
                return '/arrangement/' + this.aktivitet.id + '/rediger';
            }
            return null;
        },
        beskrivelse: function () {
            if (this.aktivitet && this.aktivitet.beskrivelse) {
                return this.aktivitet.beskrivelse;
            } else if (this.sorggruppe && this.sorggruppe.beskrivelse) {
                return this.sorggruppe.beskrivelse;
            }
            return '';
        },
        antallDeltakere: function () {
            let participants = 0;
            if (this.aktivitet && this.aktivitet.deltakere) {
                for (const participant of this.aktivitet.deltakere) {
                    if (!participant.fjernet) {
                        participants++;
                    }
                }
            }
            return participants;
        },
        visDeltakere: function () {
            if (this.type == 'SORGGRUPPE') {
                return true;
            }
            return this.aktivitet.visDeltakere;
        },
        openpamelding: function () {
            if (this.type == 'SORGGRUPPE') {
                return false;
            } else if (!this.aktivitet.aapenPaamelding) {
                return false;
            }
            return true;
        },
        pameldt: function () {
            let pameldt = false;
            if (this.aktivitet && this.aktivitet.deltakere) {
                for (const deltaker of this.aktivitet.deltakere) {
                    if (!deltaker.fjernet && deltaker.person.id == this.user.id) {
                        pameldt = true;
                    }
                }
            }
            return pameldt;
        },
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    created: async function () {
        await this.load(this.id);
    },
    methods: {
        ...mapActions('api', ['request', 'formatActivity']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * load
         */
        load: async function (id) {
            const response = await this.request({
                method: 'get',
                url: '/aktivitet/' + id,
            });

            if (response && typeof response.id != 'undefined') {
                await this.update(response);
            }
        },

        /**
         * update
         */
        update: async function (aktivitet) {
            this.aktivitet = await this.formatActivity(aktivitet);
            if (!this.sorggruppe && this.aktivitet.referanseId) {
                const response = await this.request({
                    method: 'get',
                    url: '/sorggruppe/' + this.aktivitet.referanseId,
                });
                if (response && typeof response.id != 'undefined') {
                    this.sorggruppe = response;
                }
            }
        },

        /**
         * participate
         */
        participate: async function () {
            const data = new FormData();
            data.append('personId', [this.user.id]);

            const response = await this.request({
                method: 'post',
                url: '/aktivitet/' + this.aktivitet.id + '/deltaker',
                data,
            });

            if (!response) {
                console.log('TODO: error');
            } else {
                this.update(response);
                this.snackbar('Du er nå påmeldt til arrangementet.');
            }
        },

        unparticipate: async function () {
            if (await this.confirm({ title: 'Melde av', message: 'Sikker på at du vil melde deg av arrangementet?' })) {
                const data = new FormData();
                data.append('personId', [this.user.id]);

                const response = await this.request({
                    method: 'delete',
                    url: '/aktivitet/' + this.aktivitet.id + '/deltaker',
                    data,
                });

                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                    this.snackbar('Du er nå avmeldt til arrangementet.');
                }
            }
        },

        registerReport: function () {
            this.$router.push({ path: this.routeEdit + '#rapport' });
        },
    },
};
</script>
